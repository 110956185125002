import { SearchActions } from './actions';
import { SearchActionTypes } from './types';

export interface SearchState {
  query: string;
}

const INITIAL_STATE: SearchState = {
  query: ''
};

export default (
  state: SearchState = INITIAL_STATE,
  action: SearchActions
): SearchState => {
  switch (action.type) {
    case SearchActionTypes.ClearSearch:
      return INITIAL_STATE;
    case SearchActionTypes.QuerySearch:
      return {
        ...state,
        query: action.payload.query
      };

    default:
      return state || INITIAL_STATE;
  }
};
