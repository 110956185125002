import { PageHeaderAction } from 'models';
import { Search } from 'modules/search';
import React from 'react';
import { MdKeyboardBackspace } from 'react-icons/md';
import { Link } from 'react-router-dom';

interface Props {
  title?: string | React.ReactElement;
  actions?: PageHeaderAction[];
  backAction?: PageHeaderAction;
  onSearch?: (query: string) => void;
}

export const PageHeader: React.FC<Props> = ({
  title,
  actions,
  backAction,
  onSearch
}) => {
  return (
    <header className="contentarea__heading">
      <div
        className={
          !title || !backAction
            ? 'contentarea__heading__main'
            : 'contentarea__heading__content'
        }
      >
        {renderBackButton()}
        {!backAction && renderTitle()}
        {renderActions()}
      </div>

      {backAction && renderTitle()}

      {onSearch && (
        <div className="contentarea__heading__search">{renderSearch()}</div>
      )}
    </header>
  );

  function renderAction(action?: PageHeaderAction) {
    if (!action) {
      return null;
    }

    if (action.actionHandler && isRouteLink(action.actionHandler)) {
      return (
        <Link className="btn btn--primary btn--med" to={action.actionHandler}>
          {action.name}
        </Link>
      );
    }

    if (action.actionHandler && !isRouteLink(action.actionHandler)) {
      return (
        <button
          className="btn btn--primary btn--med"
          onClick={action.actionHandler}
        >
          {action.name}
        </button>
      );
    }

    return <div>{action.name}</div>;
  }

  function renderBackButton() {
    if (!backAction || !backAction.actionHandler) {
      return null;
    }

    if (isRouteLink(backAction.actionHandler)) {
      return (
        <Link
          className="btn btn--med btn--outline btn--hasIcon"
          to={backAction.actionHandler}
        >
          <MdKeyboardBackspace size={16} />
          <span>{backAction.name}</span>
        </Link>
      );
    }

    return null;
  }

  function renderTitle() {
    if (backAction) {
      return (
        <div className="s-top--med">
          <h1 className="t-delta">{title}</h1>
        </div>
      );
    }

    return (
      <div>
        <h1 className="t-delta">{title}</h1>
      </div>
    );
  }

  function renderActions() {
    return (
      <div className="contentarea__heading__action">
        {actions &&
          actions.map((action, index) => (
            <div className="s-left--sml" key={index}>
              {renderAction(action)}
            </div>
          ))}
      </div>
    );
  }

  function renderSearch() {
    if (!onSearch) {
      return null;
    }

    return <Search onChange={onSearch} placeholder="Upiši ime mjesta" />;
  }

  function isRouteLink(
    actionHandler: string | (VoidFunction) | (([arg]: any) => void)
  ): actionHandler is string {
    return typeof actionHandler === 'string';
  }
};
