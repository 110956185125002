import firebase from 'firebase/app';

const FB_CONFIG = {
  apiKey: 'AIzaSyBdpjJ_tftmZi2W-PLw66KINSICHixmV0M',
  authDomain: 'dobro-production.firebaseapp.com',
  databaseURL: 'https://dobro-production.firebaseio.com',
  projectId: 'dobro-production',
  storageBucket: '',
  messagingSenderId: '1046190654722',
  appId: '1:1046190654722:web:2450ae04fc3eeb35d3ae1b'
};

export class FirebaseService {
  private static instance: firebase.app.App;

  public static get Instance() {
    return this.instance || (this.instance = firebase.initializeApp(FB_CONFIG));
  }
}
