import React from 'react';
import { RoutePath } from 'modules/navigation';
import { Link } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';

import { Location } from '../models';

interface Props {
  location: Location;
  toggleModal: VoidFunction;
}

export const LocationItem: React.FC<Props> = ({ location, toggleModal }) => (
  <div className="itemlist--simple__item">
    <div className="itemlist--simple__item__content">
      <Link to={`${RoutePath.Locations}/${location.id}`}>
        <h2 className="t-zeta">
          {location.storeName && <strong>{location.storeName}</strong>}
          {location.address && <div>{location.address}</div>}
          {location.city && <div>{location.city}</div>}
        </h2>
      </Link>
    </div>

    <div className="itemlist--simple__item__action">
      <div className="btn-group">
        <Link
          className="btn btn--sml btn--primary"
          to={`${RoutePath.Locations}/${location.id}`}
        >
          Uredi
        </Link>
        <button
          className="btn btn--ghost btn--sml btn--hasIcon"
          data-id={location.id}
          data-title={location.address}
          onClick={toggleModal}
        >
          <MdDelete size={16} />
        </button>
      </div>
    </div>
  </div>
);
