import {
  authenticatedRoutes,
  authenticatedDetailsRoutes
} from './authenticatedRoutes';
import { anonymousRoutes } from './anonymousRoutes';
import { errorRoutes } from './errorRoutes';

export const applicationRoutes = [
  ...authenticatedRoutes,
  ...authenticatedDetailsRoutes,
  ...anonymousRoutes,
  ...errorRoutes
];
