enum LoginActionTypes {
  AuthStateChange = 'auth/STATE_CHANGE',
  LoginSuccess = 'auth/LOGIN_SUCCESS',
  LoginError = 'auth/LOGIN_ERROR',
  Logout = 'auth/LOGOUT_USER'
}

export const AuthenticationActionTypes = {
  ...LoginActionTypes
};
