import React, { useState, HTMLAttributes } from 'react';
import { Omit } from 'react-redux';
import { MdClose } from 'react-icons/md';

type Props = Omit<HTMLAttributes<HTMLInputElement>, 'onChange'> & {
  onChange: (query: string) => void;
  clearable?: boolean;
  activateOnEnter?: boolean;
};

export const Search: React.FC<Props> = ({
  onChange,
  clearable,
  activateOnEnter,
  ...rest
}) => {
  const [query, setQuery] = useState('');

  return (
    <div className="input__search">
      {clearable && query && (
        <div className="input__search-close" onClick={handleReset}>
          <MdClose />
        </div>
      )}

      <input
        {...rest}
        id="search"
        className="input input--med"
        onKeyDown={activateOnEnter ? handleEnter : undefined}
        onChange={handleSearch}
        value={query}
      />
    </div>
  );

  function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
    setQuery(e.currentTarget.value);

    if (!e.currentTarget.value) {
      onChange('');
    }

    if (!activateOnEnter) {
      return query.length ? onChange(e.currentTarget.value) : onChange('');
    }
  }

  function handleEnter(event: React.KeyboardEvent<HTMLInputElement>) {
    if (query && event.key === 'Enter') {
      onChange(query);
    }
  }

  function handleReset() {
    onChange('');
    setQuery('');
  }
};
