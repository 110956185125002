import { Location, Company } from '../models';

import { LocationsActions } from './actions';
import { LocationsActionTypes } from './types';

export interface LocationsState {
  locationsAreChanging: boolean;
  error?: string;
  locations: Location[];
  cityFilter: string | null;
  companyFilter: Company | null;
}

const INITIAL_STATE: LocationsState = {
  locationsAreChanging: true,
  error: undefined,
  locations: [],
  cityFilter: null,
  companyFilter: null
};

export default (
  state: LocationsState = INITIAL_STATE,
  action: LocationsActions
) => {
  switch (action.type) {
    case LocationsActionTypes.AddRequest:
      return {
        ...state,
        locationsAreChanging: true,
        error: undefined
      };

    case LocationsActionTypes.AddLocations:
      return {
        ...state,
        locationsAreChanging: false,
        error: undefined,
        locations: action.payload.locations
      };

    case LocationsActionTypes.AddError:
      return {
        ...state,
        locationsAreChanging: false,
        error: action.payload.error
      };

    case LocationsActionTypes.ChangeCity:
      return {
        ...state,
        cityFilter: action.payload.city
      };

    case LocationsActionTypes.ChangeCompany:
      return {
        ...state,
        companyFilter: action.payload.company
      };

    case LocationsActionTypes.ChangeLoading:
      return {
        ...state,
        locationsAreChanging: false
      };

    default:
      return state || INITIAL_STATE;
  }
};
