import React from 'react';
import { PaginationProps, Pagination } from 'components';
import { useState } from 'react';

export const usePagination = <T extends any>(
  unpagedItems: T[],
  itemsPerPage = 30
): [T[], () => JSX.Element] => {
  const [currentPage, setCurrentPage] = useState(1);

  /**
   * Set total page count and ceil to higher integer number
   */
  const totalPages = Math.ceil(unpagedItems.length / itemsPerPage);

  /**
   * Logic for calculating the amount of returned items per page
   */
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;
  const pagedItems = unpagedItems.slice(startIndex, endIndex);

  /**
   * Generate Props for pagination component
   */
  const paginationProps: PaginationProps = {
    totalPages,
    currentPage,
    onGoToFirstPage,
    onGoToLastPage,
    onGoToNextPage,
    onGoToPage,
    onGoToPreviousPage
  };

  const paginationComponent = () => <Pagination {...paginationProps} />;

  return [pagedItems, paginationComponent];

  function onGoToFirstPage() {
    setCurrentPage(1);
  }

  function onGoToLastPage() {
    setCurrentPage(totalPages);
  }

  function onGoToNextPage() {
    setCurrentPage(currentPage + 1);
  }

  function onGoToPreviousPage() {
    setCurrentPage(currentPage - 1);
  }

  function onGoToPage(event: React.MouseEvent<HTMLButtonElement>) {
    const { pageNumber } = event.currentTarget.dataset;
    if (pageNumber) {
      setCurrentPage(parseInt(pageNumber));
    }
  }
};
