import 'firebase/auth';
import 'firebase/firestore';
import { UserInfo } from 'firebase';

import { errorMap } from '../const';

import { FirebaseService } from './FirebaseService';

enum AuthPersistence {
  Local = 'local',
  Session = 'session',
  None = 'none'
}

export class FirebaseAuthService {
  private firebase = FirebaseService.Instance;
  private auth = this.firebase.auth();

  initAuthStateListener(
    onSuccess: (user: UserInfo) => void,
    onError: (error?: string) => void
  ) {
    this.auth.onAuthStateChanged(async user => {
      if (!user) {
        return onError();
      }

      return onSuccess(user.toJSON() as any);
    });
  }

  async loginWithEmailAndPasswordAsync(
    email: string,
    password: string,
    rememberMe: boolean
  ) {
    if (this.auth.currentUser) {
      await this.logoutAsync();
    }

    return this.auth
      .setPersistence(
        rememberMe ? AuthPersistence.Local : AuthPersistence.Session
      )
      .then(() =>
        this.auth
          .signInWithEmailAndPassword(email, password)
          .then(() => undefined)
          .catch(
            async (error: firebase.FirebaseError) =>
              this.mapErrorMessages(error.code) || error.message
          )
      );
  }

  async logoutAsync() {
    return this.auth
      .signOut()
      .then()
      .catch((error: firebase.FirebaseError) => error.message);
  }

  async sendPasswordResetEmail(email: string) {
    await this.auth.sendPasswordResetEmail(email);

    return;
  }

  mapErrorMessages(code: string) {
    if (errorMap[code]) {
      return errorMap[code];
    }

    return;
  }
}
