import { createAction, ActionUnion } from 'modules/redux-store';

import { FirestoreListenerActionTypes } from './types';

const AddActions = {
  setListener: (listenerName: string, listener: VoidFunction) =>
    createAction(FirestoreListenerActionTypes.SetListener, {
      listenerName,
      listener
    })
};

const GeneralActions = {
  removeListener: (listenerName: string) =>
    createAction(FirestoreListenerActionTypes.RemoveListener, {
      listenerName
    }),
  removeAllListeners: () =>
    createAction(FirestoreListenerActionTypes.RemoveAllListeners)
};

export const FirestoreListenerActions = {
  ...AddActions,
  ...GeneralActions
};

export type FirestoreListenerActions = ActionUnion<
  typeof FirestoreListenerActions
>;
