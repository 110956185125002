import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ApplicationState } from 'modules/redux-store';
import { AuthenticationThunkActions } from 'modules/authentication';
import { Link } from 'react-router-dom';
import { RoutePath } from 'modules/navigation';

interface DispatchProps {
  loginUser: (username: string, password: string, rememberMe: boolean) => void;
}

interface ReduxProps {
  authStateChanging: boolean;
  isLoggedIn?: boolean;
  error?: string;
}

type Props = DispatchProps & ReduxProps & RouteComponentProps<any>;

interface State {
  email: string;
  password: string;
  rememberMe: boolean;
}

const Login: React.FC<Props> = ({ loginUser, authStateChanging, error }) => {
  const [state, setValues] = useState<State>({
    email: '',
    password: '',
    rememberMe: false
  });

  const { email, password, rememberMe } = state;

  return (
    <div className="wrapper wrapper--narrow">
      <section className="auth">
        <header className="auth__header">
          <h1 className="t-delta">Login</h1>
        </header>
        <form onSubmit={handleSubmit}>
          <div className="auth__content">
            <div className="field">
              <p className="alert alert--warning">{error}</p>
            </div>
            <div className="field s-bottom--lrg">
              <label htmlFor="username" className="field__lbl">
                E-mail
              </label>
              <input
                id="username"
                name="email"
                value={email}
                placeholder="Account e-mail address"
                className="input input--med input--text"
                type="email"
                onChange={handleInputChange}
              />
            </div>
            <div className="field s-bottom--lrg">
              <label htmlFor="password" className="field__lbl">
                Password
              </label>
              <input
                id="password"
                name="password"
                value={password}
                placeholder="Enter your password"
                className="input input--med input--text"
                type="password"
                onChange={handleInputChange}
              />
            </div>
            <div className="field">
              <input
                id="rememberMe"
                name="rememberMe"
                checked={rememberMe}
                className="input--check"
                type="checkbox"
                onChange={handleInputChange}
              />
              <label htmlFor="rememberMe">Remember me?</label>
            </div>
          </div>
          <div className="auth__action btn-group">
            <button
              type="submit"
              value="Submit"
              className="btn btn--med btn--primary"
              disabled={authStateChanging}
            >
              Login
            </button>

            <Link
              className="btn btn--ghost btn--med"
              to={RoutePath.ForgotPassword}
            >
              Forgot password?
            </Link>
          </div>
        </form>
      </section>
    </div>
  );

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    loginUser(email, password, rememberMe);
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, type, checked, value } = event.currentTarget;
    const inputValue = type === 'checkbox' ? checked : value;

    setValues({
      ...state,
      [name]: inputValue
    });
  }
};

export default connect<ReduxProps, DispatchProps, null, ApplicationState>(
  state => ({
    authStateChanging: state.auth.authStateChanging,
    isLoggedIn: state.auth.isLoggedIn,
    error: state.auth.error
  }),
  {
    loginUser: AuthenticationThunkActions.loginAsync
  }
)(Login);
