import React from 'react';
import 'normalize.css';
import 'css/app.css';
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';
import { AccessControlList } from 'modules/authorization';
import { Provider } from 'react-redux';
import { configureStore } from 'modules/redux-store';
import { AppLayout, Loading } from 'components';
import { PersistGate } from 'redux-persist/integration/react';
import { useInitGoogleMap } from 'hooks';

export const App: React.FC = () => {
  const persistedStore = configureStore();
  const { store, persistor } = persistedStore;

  const [mapReady] = useInitGoogleMap();

  /**
   * Return App components
   */
  return (
    <Provider store={store}>
      <PersistGate
        loading={<Loading fullPage isLoading />}
        persistor={persistor}
      >
        <Loading fullPage isLoading={!mapReady}>
          <Router history={createBrowserHistory()}>
            <AppLayout>
              <AccessControlList />
            </AppLayout>
          </Router>
        </Loading>
      </PersistGate>
    </Provider>
  );
};
