import React from 'react';
import { useCallback, useState } from 'react';
import { Modal } from 'components';

export const useModal = (
  onSuccess: (entityId: string, type?: string) => void,
  onRedirect?: VoidFunction
): [
  (event?: React.MouseEvent<HTMLButtonElement>) => void,
  () => JSX.Element
] => {
  const [isOpen, setIsOpen] = useState(false);
  const [entityId, setEntityId] = useState('');

  const onToggle = useCallback(toggleModal, [isOpen]);

  const modalComponent = () => (
    <Modal
      isOpen={isOpen}
      onClose={onToggle}
      onSuccess={removeItem}
      type="danger"
    />
  );

  return [toggleModal, modalComponent];

  function removeItem() {
    onSuccess(entityId);
    setIsOpen(false);

    if (onRedirect) {
      onRedirect();
    }
  }

  function toggleModal(event?: React.MouseEvent<HTMLButtonElement>) {
    /**
     * Toggle current modal open state
     */
    setIsOpen(!isOpen);

    /**
     * Set entity id if event exists
     */
    if (event) {
      const { id } = event.currentTarget.dataset;

      if (id) {
        setEntityId(id);
      }
    }
  }
};
