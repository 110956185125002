export interface PageHeaderAction {
  name: string | React.ReactElement;
  actionHandler?: string | (VoidFunction) | (([arg]: any) => void);
}

export class PageHeaderAction {
  constructor(
    name: string | React.ReactElement,
    actionHandler?: string | (VoidFunction) | (([arg]: any) => void)
  ) {
    this.name = name;
    this.actionHandler = actionHandler;
  }
}
