import { Loading } from 'components';
import React, { useState } from 'react';
import {
  Switch,
  Redirect,
  RouteComponentProps,
  withRouter
} from 'react-router-dom';
import {
  AppRoute,
  getRoute,
  RoutePath,
  applicationRoutes
} from 'modules/navigation';
import { connect } from 'react-redux';
import { ApplicationState } from 'modules/redux-store';
import { AuthenticationThunkActions } from 'modules/authentication';
import { useEffect } from 'react';
import { SearchActions } from 'modules/search';
import { anonymousRoutes } from 'modules/navigation/const/anonymousRoutes';
import { errorRoutes } from 'modules/navigation/const/errorRoutes';
import {
  authenticatedRoutes,
  authenticatedDetailsRoutes
} from 'modules/navigation/const/authenticatedRoutes';

import { ACLRoute } from './ACLRoute';

interface ReduxProps {
  authStateChanging: boolean;
  user?: any;
  isLoggedIn: boolean;
}

interface DispatchProps {
  loginWithPersistedUser: VoidFunction;
  clearSearch: VoidFunction;
}

type Props = ReduxProps & RouteComponentProps<any> & DispatchProps;

const AccessControlList: React.FC<Props> = ({
  loginWithPersistedUser,
  authStateChanging,
  clearSearch,
  user,
  location,
  ...rest
}) => {
  const [enabledRoutes, setEnabledRoutes] = useState();

  const allRoutes = applicationRoutes.map(route => route.path);

  useEffect(() => {
    loginWithPersistedUser();
  }, [loginWithPersistedUser]);

  useEffect(() => {
    const routing = [
      ...anonymousRoutes,
      ...errorRoutes,
      ...authenticatedRoutes,
      ...authenticatedDetailsRoutes
    ];

    setEnabledRoutes(routing);
  }, [user]);

  return (
    <Loading isLoading={authStateChanging || !enabledRoutes} fullPage>
      <Switch>
        <Redirect exact from="/" to={getRoute(RoutePath.Login).path} />

        {enabledRoutes &&
          enabledRoutes.map((route: AppRoute) => (
            <ACLRoute
              location={location}
              key={route.key}
              {...route}
              {...rest}
              clearSearch={clearSearch}
            />
          ))}

        {!allRoutes.find(route => route === location.pathname) && (
          <Redirect to={getRoute(RoutePath.Error).path} />
        )}
      </Switch>
    </Loading>
  );
};

export default withRouter(
  connect<ReduxProps, DispatchProps, null, ApplicationState>(
    state => ({
      isLoggedIn: state.auth.isLoggedIn,
      authStateChanging: state.auth.authStateChanging,
      user: state.auth.user
    }),
    {
      loginWithPersistedUser: AuthenticationThunkActions.loginWithPersistedUser,
      clearSearch: SearchActions.clearSearch
    }
  )(AccessControlList)
);
