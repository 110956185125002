import { CollectionLastModified } from '../models';

import { LastModifiedActions } from './actions';
import { LastModifiedActionTypes } from './types';

export interface LastModifiedState {
  isChanging: boolean;
  error?: string;
  lastModified: CollectionLastModified[];
}

const INITIAL_STATE: LastModifiedState = {
  isChanging: true,
  error: undefined,
  lastModified: []
};

export default (
  state: LastModifiedState = INITIAL_STATE,
  action: LastModifiedActions
) => {
  switch (action.type) {
    case LastModifiedActionTypes.AddRequest:
      return {
        ...state,
        isChanging: true,
        error: undefined
      };

    case LastModifiedActionTypes.AddLastModified:
      return {
        ...state,
        isChanging: false,
        error: undefined,
        lastModified: action.payload.lastModified
      };

    case LastModifiedActionTypes.AddError:
      return {
        ...state,
        isChanging: false,
        error: action.payload.error
      };

    default:
      return state || INITIAL_STATE;
  }
};
