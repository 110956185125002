import React from 'react';
import { RouteComponentProps, NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { AuthenticationThunkActions } from 'modules/authentication';
import { ApplicationState } from 'modules/redux-store';

import { version } from '../../../../package.json';
import { AppRoute, RoutePath } from '../models';
import { authenticatedRoutes } from '../const/authenticatedRoutes';

import NavDropdown from './NavDropdown';

interface ReduxProps {
  isLoggedIn: boolean;
  user?: any;
}

interface DispatchProps {
  logout: VoidFunction;
}

type Props = RouteComponentProps<any> & DispatchProps & ReduxProps;

const Navigation: React.FC<Props> = ({
  logout,
  isLoggedIn,
  user,
  history,
  location
}) => {
  if (!isLoggedIn || location.pathname.includes('map')) {
    return null;
  }

  return (
    <div className="masterlayout__aside">
      <aside className="sidebar">
        <div className="sidebar__top">
          <div className="sidebar__branding">
            <img
              src={require('assets/images/dobro-white-logo.svg')}
              alt="Dobro"
            />
          </div>
          <div className="sidebar__nav">
            <nav className="nav">
              <ul className="nav__list">{renderNavigationMenu()}</ul>
            </nav>
          </div>
        </div>
        <div className="sidebar__bottom">
          <div className="sidebar__version">Verzija: {version}</div>
          <button className="btn sidebar__logout" onClick={handleLogout}>
            Odjava
          </button>
        </div>
      </aside>
    </div>
  );

  function renderNavigationMenu() {
    if (!user) {
      return;
    }

    const navigationRoutes: AppRoute[] = [];

    authenticatedRoutes.forEach(route => {
      navigationRoutes.push(route);
    });

    return navigationRoutes.map(route => {
      if (!route.childLinks) {
        return (
          <li key={route.path} className="nav__list__item">
            <NavLink to={route.path} className="nav__link">
              {route.title}
            </NavLink>
          </li>
        );
      }

      return (
        <NavDropdown
          title={route.title}
          key={route.path}
          childLinks={route.childLinks}
        />
      );
    });
  }

  async function handleLogout() {
    await logout();

    history.push(RoutePath.Login);
  }
};

export default withRouter(
  connect<ReduxProps, DispatchProps, null, ApplicationState>(
    state => ({
      isLoggedIn: state.auth.isLoggedIn,
      user: state.auth.user
    }),
    {
      logout: AuthenticationThunkActions.logout
    }
  )(Navigation)
);
