import { RoutePath } from 'modules/navigation';
import React, { useState, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from 'modules/redux-store';
import { Loading, PageHeader, EmptyState, PageGuard } from 'components';
import { connect } from 'react-redux';
import { PageHeaderAction } from 'models';
import { useModal, usePageModified } from 'hooks';
import { MdDelete } from 'react-icons/md';
import {
  Location,
  Map,
  CompanyOptions,
  LocationsActions
} from 'modules/locations';
import { LocationsThunks } from 'modules/locations';
import Select from 'react-select';
import { ValueType } from 'react-select/src/types';
import { useLastModified, CollectionLastModified } from 'modules/lastModified';
import { LastModifiedThunks } from 'modules/lastModified/redux/thunks';
import { FirebaseCollection } from 'const';
declare const google: any;

interface DispatchProps {
  getAll: VoidFunction;
  saveLocation: (location: Location) => void;
  removeLocation: (id: string) => void;
  getLastModified: VoidFunction;
  changeLocationsLoading: VoidFunction;
}

interface ReduxProps {
  locationData: Location;
  locationsAreChanging: boolean;
  lastModified: CollectionLastModified[];
  lastModifiedChanging: boolean;
}

type Props = DispatchProps & ReduxProps & RouteComponentProps<any>;

const LocationDetails: React.FC<Props> = ({
  locationData,
  saveLocation,
  history,
  locationsAreChanging,
  removeLocation,
  getLastModified,
  changeLocationsLoading,
  lastModified,
  lastModifiedChanging,
  getAll
}) => {
  useLastModified(
    getLastModified,
    lastModified.find(doc => doc.id === FirebaseCollection.Locations),
    getAll,
    lastModifiedChanging,
    changeLocationsLoading
  );

  const [toggleModal, Modal] = useModal(removeLocation, redirectToList);
  const [state, setState] = useState(locationData);
  const stateRef = useRef<Location>(locationData);
  stateRef.current = state;
  const [center, setCenter] = useState();
  const [error, setError] = useState();
  const [pageModified, setPageModified] = usePageModified(
    'location-contentarea'
  );
  const [geocoder] = useState(new google.maps.Geocoder());
  const [suggestion, setSuggestion] = useState<{
    name: string;
    coordinates: any;
  }>();
  const [showSuggestion, toggleShowSuggestion] = useState(false);

  useEffect(() => {
    if (!state) {
      return;
    }

    const inputAddress = `${state.city} ${state.address}`;

    if (state.address.length > 2 && pageModified) {
      geocoder.geocode(
        { address: inputAddress, region: 'Croatia' },
        (results: any, status: string) => {
          if (status === 'OK') {
            const latLng = results[0].geometry.location;
            const suggestionInfo = results[0].address_components
              .slice(0, 3)
              .map((name: any) => name.long_name);

            suggestionInfo.reverse().join(', ');
            const suggestionData = {
              name: suggestionInfo.reverse().join(', '),
              coordinates: latLng
            };
            setSuggestion(suggestionData);
          }
        }
      );
    }
  }, [geocoder, pageModified, state]);

  useEffect(() => {
    if (!locationData) {
      return;
    }

    setState(locationData);
  }, [locationData]);

  if (!state) {
    if (!locationsAreChanging) {
      return <EmptyState showImage />;
    }

    return <Loading isLoading fullPage />;
  }

  const { id, storeName, address, city, postCode, company, position } = state;

  return (
    <section className="contentarea" id="location-contentarea">
      <PageHeader
        title="Detalji lokacije"
        backAction={
          new PageHeaderAction('Natrag na listu', RoutePath.Locations)
        }
      />

      {error && (
        <div className="alert alert--warning s-bottom--med">{error}</div>
      )}

      <div className="card">
        <div className="field">
          <label htmlFor="storeName" className="field__lbl t-mandatory">
            Naziv prodavaonice
          </label>
          <input
            type="text"
            className="input input--med"
            name="storeName"
            id="storeName"
            placeholder="Unesite naziv prodavaonice"
            value={storeName}
            onChange={handleChange}
          />
        </div>

        <div className="field">
          <label htmlFor="city" className="field__lbl t-mandatory">
            Grad
          </label>
          <input
            type="text"
            className="input input--med"
            name="city"
            id="city"
            placeholder="Unesite naziv grada"
            value={city}
            onChange={handleChange}
          />
        </div>

        <div className="field">
          <label htmlFor="postCode" className="field__lbl">
            Poštanski broj
          </label>
          <input
            type="text"
            className="input input--med"
            name="postCode"
            id="postCode"
            placeholder="Unesite poštanski broj"
            value={postCode}
            onChange={handleChange}
          />
        </div>

        <div className="field map__input-suggestion">
          <label htmlFor="address" className="field__lbl t-mandatory">
            Adresa
          </label>
          <input
            type="text"
            className="input input--med"
            name="address"
            id="address"
            placeholder="Unesite adresu"
            value={address}
            onChange={handleChange}
            onFocus={handleShowSuggestion}
            onBlur={handleCloseSuggestion}
          />

          {showSuggestion && suggestion && (
            <div
              className="map__suggestion input--med"
              onClick={insertCoordinates}
            >
              {suggestion.name}
            </div>
          )}
        </div>

        <div className="field">
          <label htmlFor="company" className="field__lbl t-mandatory">
            Tvrtka
          </label>
          <Select
            value={{ value: company, label: company }}
            onChange={handleCompanyChange}
            options={CompanyOptions}
            id="company"
            className="input--select s-bottom--lrg"
          />
        </div>

        <div className="field">
          <label htmlFor="position" className="field__lbl t-mandatory">
            Položaj
          </label>
          <div className="input-group">
            <input
              type="text"
              className="input input--med u-width--20 s-right--sml"
              name="lat"
              placeholder="Geografska širina"
              value={position ? position.lat : undefined}
              onChange={handleCoordinatesChange}
              disabled
            />
            <input
              type="text"
              className="input input--med u-width--20"
              name="lng"
              placeholder="Geografska dužina"
              value={position ? position.lng : undefined}
              onChange={handleCoordinatesChange}
              disabled
            />
          </div>
        </div>

        <Map
          position={{ lat: Number(position.lat), lng: Number(position.lng) }}
          company={company}
          handlePositionChange={handlePositionChange}
          newCenter={center}
          storeName={storeName}
        />

        <footer className="card__footer">
          <div className="btn-group">
            <button
              className="btn btn--primary btn--med"
              onClick={handleSave}
              disabled={!pageModified}
            >
              Spremi
            </button>
            {id && (
              <button
                className="btn btn--ghost btn--med btn--hasIcon"
                onClick={toggleModal}
                data-id={id}
                data-title={storeName}
              >
                <MdDelete size={16} />
              </button>
            )}
          </div>
        </footer>
      </div>

      <Modal />

      <PageGuard when={pageModified} />
    </section>
  );

  function handleShowSuggestion() {
    toggleShowSuggestion(true);
  }

  function handleCloseSuggestion() {
    if (showSuggestion) {
      setTimeout(() => {
        toggleShowSuggestion(false);
      }, 200);
    }
  }

  function handleCoordinatesChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.currentTarget;
    setState({
      ...state,
      position: {
        ...state.position,
        [name]: value
      }
    });
  }

  function insertCoordinates() {
    if (suggestion && suggestion.coordinates) {
      setCenter({
        lat: suggestion.coordinates.lat(),
        lng: suggestion.coordinates.lng()
      });
    }
    handleCloseSuggestion();
  }

  function handleChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const { name, value } = event.currentTarget;

    setState({
      ...state,
      [name]: value
    });
  }

  function handlePositionChange(positionData: { lat: string; lng: string }) {
    setState({
      ...stateRef.current,
      position: positionData
    });

    setPageModified(true);
  }

  function handleCompanyChange(
    value: ValueType<{
      value: string;
      label: string | React.ReactElement;
    }>
  ) {
    setState({
      ...state,
      company: (value as any).value
    });

    setPageModified(true);
  }

  async function handleSave() {
    if (!isFormValid()) {
      return;
    }

    await setPageModified(false);

    await saveLocation({ ...state });

    redirectToList();
  }

  function redirectToList() {
    history.push(RoutePath.Locations);
  }

  function isFormValid() {
    if (
      !storeName ||
      !city ||
      !address ||
      !position ||
      !position.lat ||
      !position.lng
    ) {
      setError(
        <>
          <strong>Informacije koje nedostaju:</strong>
          {!storeName && <div>Naziv poslovnice</div>}
          {!city && <div>Grad</div>}
          {!address && <div>Adresa</div>}
          {(!position || !position.lat) && <div>Geografska širina</div>}
          {(!position || !position.lng) && <div>Geografska dužina</div>}
        </>
      );
      return false;
    }

    return true;
  }
};

export default connect<ReduxProps, DispatchProps, Props, ApplicationState>(
  (state, componentProps) => {
    const id = componentProps.match.params.id;

    return {
      locationData:
        id === 'new'
          ? new Location()
          : state.locations.locations.filter(item => item.id === id)[0],
      locationsAreChanging: state.locations.locationsAreChanging,
      lastModified: state.lastModified.lastModified,
      lastModifiedChanging: state.lastModified.isChanging
    };
  },
  {
    removeLocation: LocationsThunks.remove,
    getAll: LocationsThunks.getAllAsync,
    saveLocation: LocationsThunks.saveLocation,
    getLastModified: LastModifiedThunks.getAllAsync,
    changeLocationsLoading: LocationsActions.changeLoading
  }
)(LocationDetails);
