import { ApplicationState } from 'modules/redux-store';
import { FirestoreListenerActions } from 'modules/firebase';
import { Dispatch } from 'redux';

import { Location } from '../models';
import { LocationsService } from '../services';

import { LocationsActions } from './actions';

const getAllAsync = () => async (
  dispatch: Dispatch,
  getState: () => ApplicationState
) => {
  /** If listener is already active, don't trigger again */
  const locationsListener = getState().listeners.locationsListener;
  if (locationsListener) {
    return;
  }

  dispatch(LocationsActions.addRequest());

  const successFunction = (data: Location[]) =>
    dispatch(LocationsActions.addLocations(data));

  const errorFunction = (error: string) =>
    dispatch(LocationsActions.addError(error));

  const listenerProps = {
    successFunction,
    errorFunction
  };

  LocationsService.filterAsync(undefined, listenerProps).then(listener => {
    if (typeof listener === 'function') {
      dispatch(
        FirestoreListenerActions.setListener('locationsListener', listener)
      );
    }
  });

  return;
};

const saveLocation = (location: Location | Location[]) => (
  dispatch: Dispatch
) => {
  dispatch(LocationsActions.addRequest());

  LocationsService.addAsync(location);
};

const remove = (id: string) => () => {
  LocationsService.removeAsync(id);
};

export const LocationsThunks = {
  getAllAsync,
  saveLocation,
  remove
};
