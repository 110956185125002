import { AppRoute, getRoute, RoutePath } from 'modules/navigation';
import React, { useEffect } from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';

import { ACLType } from '../models';

interface OwnProps {
  isLoggedIn: boolean;
  clearSearch: VoidFunction;
}

type Props = OwnProps & AppRoute & RouteComponentProps<any>;

export const ACLRoute: React.FC<Props> = ({
  key,
  accessbilityLevel,
  isLoggedIn,
  location,
  clearSearch,
  ...rest
}) => {
  useEffect(() => {
    clearSearch();
  }, [clearSearch]);

  // Redirect user to permission error page if anonymous user tries to access authenticated route
  if (!isLoggedIn && accessbilityLevel === ACLType.Authenticated) {
    return <Redirect key={key} to={getRoute(RoutePath.Unauthorized).path} />;
  }

  // Redirect user to summary if he is on unauthorized error page but logged in
  if (
    isLoggedIn &&
    location.pathname === getRoute(RoutePath.Unauthorized).path
  ) {
    return <Redirect key={key} to={getRoute(RoutePath.Locations).path} />;
  }

  // Redirect user to summary if authenticated user tries to access AnonymousOnly route
  if (isLoggedIn && accessbilityLevel === ACLType.AnonymousOnly) {
    return <Redirect key={key} to={getRoute(RoutePath.Locations).path} />;
  }

  return <Route key={key} {...rest} />;
};
