import { createAction, ActionUnion } from 'modules/redux-store';

import { CollectionLastModified } from '../models';

import { LastModifiedActionTypes } from './types';

const AddActions = {
  addRequest: () => createAction(LastModifiedActionTypes.AddRequest),
  AddLastModified: (lastModified: CollectionLastModified[]) =>
    createAction(LastModifiedActionTypes.AddLastModified, {
      lastModified
    }),
  addError: (error: string) =>
    createAction(LastModifiedActionTypes.AddError, { error })
};

export const LastModifiedActions = {
  ...AddActions
};

export type LastModifiedActions = ActionUnion<typeof LastModifiedActions>;
