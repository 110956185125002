import { createAction, ActionUnion } from 'modules/redux-store';
import { UserInfo } from 'firebase';

import { AuthenticationActionTypes } from './types';

export const AuthenticationActions = {
  authStateChange: () =>
    createAction(AuthenticationActionTypes.AuthStateChange),

  loginSuccess: (user: UserInfo) =>
    createAction(AuthenticationActionTypes.LoginSuccess, { user }),

  loginError: (error?: string) =>
    createAction(AuthenticationActionTypes.LoginError, { error }),

  logout: () => createAction(AuthenticationActionTypes.Logout)
};

export type AuthenticationActions = ActionUnion<typeof AuthenticationActions>;
