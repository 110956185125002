import React from 'react';
import { ApplicationState } from 'modules/redux-store';
import { connect } from 'react-redux';
import { Loading, PageHeader, EmptyState } from 'components';
import {
  Location,
  LocationItem,
  LocationsThunks,
  LocationsActions,
  LocationsSelectors,
  CompanyOptions,
  Company
} from 'modules/locations';
import { RoutePath } from 'modules/navigation';
import { PageHeaderAction } from 'models';
import { SearchQuery, SearchActions, SearchSelectors } from 'modules/search';
import { usePagination, useModal } from 'hooks';
import Select from 'react-select';
import { ValueType } from 'react-select/src/types';
import { CollectionLastModified, useLastModified } from 'modules/lastModified';
import { LastModifiedThunks } from 'modules/lastModified/redux/thunks';
import { FirebaseCollection } from 'const';

interface DispatchProps {
  getAll: VoidFunction;
  removeLocation: (id: string) => void;
  search: SearchQuery;
  changeCity: (city: string | null) => void;
  changeCompany: (city: Company | null) => void;
  getLastModified: VoidFunction;
  changeLocationsLoading: VoidFunction;
}

interface ReduxProps {
  locations: Location[];
  error?: string;
  locationsAreChanging: boolean;
  cityFilterOptions: Array<{ value: string; label: string }>;
  cityFilter: string | null;
  companyFilter: string | null;
  lastModified: CollectionLastModified[];
  lastModifiedChanging: boolean;
}

type Props = DispatchProps & ReduxProps;

const LocationsList: React.FC<Props> = ({
  error,
  locationsAreChanging,
  removeLocation,
  locations,
  search,
  cityFilterOptions,
  changeCity,
  cityFilter,
  changeCompany,
  companyFilter,
  lastModified,
  getLastModified,
  getAll,
  lastModifiedChanging,
  changeLocationsLoading
}) => {
  const [toggleModal, Modal] = useModal(removeLocation);
  const [pagedItems, Pagination] = usePagination(locations);

  useLastModified(
    getLastModified,
    lastModified.find(doc => doc.id === FirebaseCollection.Locations),
    getAll,
    lastModifiedChanging,
    changeLocationsLoading
  );

  return (
    <section className="contentarea">
      <PageHeader
        onSearch={search}
        title="Lista lokacija"
        actions={[
          new PageHeaderAction(
            'Dodaj novu lokaciju',
            `${RoutePath.Locations}/new`
          )
        ]}
      />

      <div className="locations__filters">
        <div className="field">
          <label htmlFor="city_filter" className="field__lbl">
            Mjesta
          </label>
          <Select
            value={
              cityFilter
                ? cityFilterOptions.find(option => option.value === cityFilter)
                : null
            }
            onChange={handleCityChange}
            options={cityFilterOptions}
            placeholder="Odaberite mjesto"
            isClearable
            id="city_filter"
            className="input--select"
          />
        </div>

        <div className="field">
          <label htmlFor="company_filter" className="field__lbl">
            Tvrtke
          </label>
          <Select
            value={
              companyFilter
                ? CompanyOptions.find(option => option.value === companyFilter)
                : null
            }
            onChange={handleCompanyChange}
            options={CompanyOptions}
            placeholder="Odaberite tvrtku"
            isClearable
            id="company_filter"
            className="input--select"
          />
        </div>
      </div>

      {error && <div className="alert alert--warning">{error}</div>}

      <Loading fullPage isLoading={locationsAreChanging}>
        {locations && !locations.length && <EmptyState showImage />}

        {pagedItems.map(location => (
          <LocationItem
            location={location}
            key={location.id}
            toggleModal={toggleModal}
          />
        ))}

        <Pagination />
        <Modal />
      </Loading>
    </section>
  );

  function handleCityChange(
    value: ValueType<{
      value: string;
      label: string;
    }> | null
  ) {
    if (value) {
      changeCity((value as any).value);
      return;
    }

    changeCity(null);
  }

  function handleCompanyChange(
    value: ValueType<{
      value: string;
      label: string;
    }> | null
  ) {
    if (value) {
      changeCompany((value as any).value);
      return;
    }

    changeCompany(null);
  }
};

export default connect<ReduxProps, DispatchProps, null, ApplicationState>(
  state => ({
    locations: LocationsSelectors.sortByCompany(state),
    error: state.locations.error,
    locationsAreChanging: state.locations.locationsAreChanging,
    cityFilterOptions: SearchSelectors.cityFilterOptions(state),
    cityFilter: state.locations.cityFilter,
    companyFilter: state.locations.companyFilter,
    lastModified: state.lastModified.lastModified,
    lastModifiedChanging: state.lastModified.isChanging
  }),
  {
    removeLocation: LocationsThunks.remove,
    getAll: LocationsThunks.getAllAsync,
    search: SearchActions.querySearch,
    changeCity: LocationsActions.changeCity,
    changeCompany: LocationsActions.changeCompany,
    getLastModified: LastModifiedThunks.getAllAsync,
    changeLocationsLoading: LocationsActions.changeLoading
  }
)(LocationsList);
