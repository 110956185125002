import { createAction, ActionUnion } from 'modules/redux-store';
import { Company } from 'modules/locations';

import { Location } from '../models';

import { LocationsActionTypes } from './types';

const AddActions = {
  addRequest: () => createAction(LocationsActionTypes.AddRequest),
  addLocations: (locations: Location[]) =>
    createAction(LocationsActionTypes.AddLocations, { locations }),
  addError: (error: string) =>
    createAction(LocationsActionTypes.AddError, { error })
};

const ChangeActions = {
  changeCity: (city: string | null) =>
    createAction(LocationsActionTypes.ChangeCity, { city }),
  changeCompany: (company: Company | null) =>
    createAction(LocationsActionTypes.ChangeCompany, { company }),
  changeLoading: () => createAction(LocationsActionTypes.ChangeLoading)
};

export const LocationsActions = {
  ...AddActions,
  ...ChangeActions
};

export type LocationsActions = ActionUnion<typeof LocationsActions>;
