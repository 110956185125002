import { authenticationReducer } from 'modules/authentication';
import { locationsReducer } from 'modules/locations';
import { firestoreListenerReducer } from 'modules/firebase';
import { searchReducer } from 'modules/search';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { lastModifiedReducer } from 'modules/lastModified';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const setPersistorConfig = (
  key: string,
  whitelist?: string[],
  blacklist?: string[]
) => ({
  key,
  storage,
  whitelist,
  blacklist
});

export const configureStore = () => {
  /**
   * Create the composing function for our middlewares
   * Include dev tools support
   */
  const composeEnhancers = composeWithDevTools({});

  /**
   * Merge all reducers into a single object
   */
  const persistedRootReducer = {
    auth: persistReducer(
      setPersistorConfig('auth', ['user', 'isLoggedIn']),
      authenticationReducer
    ),
    locations: persistReducer(
      setPersistorConfig('locations', ['locations']),
      locationsReducer
    ),
    search: searchReducer,
    lastModified: persistReducer(
      setPersistorConfig('lastModified', ['lastModified']),
      lastModifiedReducer
    ),
    listeners: firestoreListenerReducer
  };

  /**
   * We'll create our store with the combined reducers and all enchancers
   */
  const store = createStore(
    combineReducers(persistedRootReducer),
    {},
    composeEnhancers(applyMiddleware(thunk))
  );

  const persistor = persistStore(store);

  return { store, persistor };
};
