import { FirebaseAuthService, FirestoreListenerThunks } from 'modules/firebase';
import { UserInfo } from 'firebase';
import { Dispatch, AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { AuthenticationActions } from './actions';

const firebaseAuth = new FirebaseAuthService();

const loginAsync = (
  email: string,
  password: string,
  rememberMe: boolean
) => async (dispatch: Dispatch) => {
  dispatch(AuthenticationActions.authStateChange());

  const error = await firebaseAuth.loginWithEmailAndPasswordAsync(
    email,
    password,
    rememberMe
  );

  if (error) {
    dispatch(AuthenticationActions.loginError(error));
  }
};

const loginSuccessActions = (user: UserInfo) => (dispatch: Dispatch) => {
  dispatch(AuthenticationActions.loginSuccess(user));
};

const loginWithPersistedUser = () => (dispatch: Dispatch) => {
  dispatch(AuthenticationActions.authStateChange());

  firebaseAuth.initAuthStateListener(
    (user: UserInfo) => loginSuccessActions(user)(dispatch),
    (error?: string) => dispatch(AuthenticationActions.loginError(error))
  );
};

const logout = () => async (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  dispatch(await FirestoreListenerThunks.unsubscribeListeners());
  await firebaseAuth.logoutAsync();
  dispatch(AuthenticationActions.logout());

  return;
};

const forgotPassword = (email: string) => async () => {
  await firebaseAuth.sendPasswordResetEmail(email);

  return;
};

export const AuthenticationThunkActions = {
  loginAsync,
  loginWithPersistedUser,
  logout,
  forgotPassword
};
