import { Company } from './Company';

export interface Location {
  id?: string;
  storeName: string;
  address: string;
  city: string;
  postCode?: string;
  company: Company;
  position: {
    lat: string | number;
    lng: string | number;
  };
}

export class Location {
  constructor(
    id?: string,
    storeName?: string,
    address?: string,
    city?: string,
    postCode?: string,
    company?: Company,
    position?: {
      lat: string | number;
      lng: string | number;
    }
  ) {
    this.storeName = storeName || '';
    this.address = address || '';
    this.city = city || '';
    this.company = company || Company.Dobro;
    this.postCode = postCode || '';
    this.position = position || {
      lat: '',
      lng: ''
    };

    if (id) {
      this.id = id;
    }
  }
}
