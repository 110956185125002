import { useEffect, useState } from 'react';

export const usePageModified = (
  id: string
): [boolean, (state: boolean) => void] => {
  const [pageModified, setModified] = useState(false);

  useEffect(() => {
    const page = document.getElementById(id);

    if (page) {
      page.addEventListener('keydown', () => setModified(true), false);
    }

    return () => {
      if (page) {
        page.removeEventListener('keydown', () => setModified(true), false);
      }
    };
  });

  return [pageModified, setPageModified];

  function setPageModified(state: boolean) {
    setModified(state);

    return;
  }
};
