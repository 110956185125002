import * as views from 'views';
import { ACLType } from 'modules/authorization/models';

import { AppRoute, RoutePath } from '../models';

export const errorRoutes: AppRoute[] = [
  {
    key: 'unauthorized',
    accessbilityLevel: ACLType.Public,
    component: views.ErrorPermissions,
    exact: true,
    path: RoutePath.Unauthorized,
    title: 'Not authorized'
  },
  {
    // Fallback route, always render last
    key: 'error',
    fallbackRoute: true,
    accessbilityLevel: ACLType.Public,
    component: views.ErrorGeneral,
    path: RoutePath.Error,
    title: 'Error!'
  }
];
