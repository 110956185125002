import React from 'react';
import { Navigation } from 'modules/navigation';
import { withRouter, RouteComponentProps } from 'react-router';

const AppLayout: React.FC<RouteComponentProps<any>> = ({
  children,
  location
}) => (
  <div className="container">
    <div className="masterlayout">
      <Navigation />
      <div
        className={
          location.pathname.includes('map')
            ? 'masterlayout__map'
            : 'masterlayout__main'
        }
      >
        <main>{children}</main>
      </div>
    </div>
  </div>
);

export default withRouter(AppLayout);
