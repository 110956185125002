import { createAction, ActionUnion } from 'modules/redux-store';

import { SearchActionTypes } from './types';

export const SearchActions = {
  querySearch: (query: string) =>
    createAction(SearchActionTypes.QuerySearch, {
      query
    }),

  clearSearch: () => createAction(SearchActionTypes.ClearSearch)
};

export type SearchActions = ActionUnion<typeof SearchActions>;
