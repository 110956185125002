import { SearchSelectors } from 'modules/search';
import { ApplicationState } from 'modules/redux-store';
import { createSelector } from 'reselect';

const getCityFilter = (state: ApplicationState) => state.locations.cityFilter;

const getCompanyFilter = (state: ApplicationState) =>
  state.locations.companyFilter;

const sortByCity = createSelector(
  [SearchSelectors.searchLocations, getCityFilter],
  (locations, cityFilter) =>
    locations.filter(location =>
      cityFilter ? location.city === cityFilter : location
    )
);

const sortByCompany = createSelector(
  [sortByCity, getCompanyFilter],
  (locations, companyFilter) =>
    locations.filter(location =>
      companyFilter ? location.company === companyFilter : location
    )
);

export const LocationsSelectors = {
  sortByCity,
  sortByCompany
};
