import React, { useState, useEffect, useCallback, ReactElement } from 'react';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';

interface OwnProps {
  title?: string | ReactElement;
  childLinks: Array<{ title: string | React.ReactElement; path: string }>;
}

type Props = OwnProps & RouteComponentProps<any>;

const NavDropdown: React.FC<Props> = ({
  title,
  childLinks,
  location,
  history
}) => {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);

  const checkIfChildIsOpen = useCallback(() => {
    return childLinks
      .map(link => link.path)
      .map(link => {
        if (location.pathname.split('/')[2]) {
          return link.includes(location.pathname.split('/')[2]);
        }
        return false;
      })
      .includes(true);
  }, [childLinks, location.pathname]);

  useEffect(() => {
    if (checkIfChildIsOpen()) {
      setOpen(true);
      setActive(true);

      return;
    }

    setOpen(false);
    setActive(false);
  }, [location, childLinks, checkIfChildIsOpen]);

  useEffect(() => {
    if (open && !active) {
      history.push(childLinks[0].path);
    }
  }, [open, history, childLinks, active]);

  return (
    <>
      <li
        className="nav__list__item"
        onClick={handleOpen}
        onKeyPress={handleOpenOnEnter}
        tabIndex={0}
        role="button"
      >
        <div
          className={`nav__link nav__link--dropdown ${
            active ? 'nav__button--active' : ''
          }`}
        >
          {title}
        </div>
      </li>

      {open &&
        childLinks.map((link, index) => (
          <li key={index} className="nav__list__item">
            <NavLink to={link.path} className="nav__link nav__link__child">
              {link.title}
            </NavLink>
          </li>
        ))}
    </>
  );

  function handleOpen() {
    setOpen(!open);
  }

  function handleOpenOnEnter(event: React.KeyboardEvent<HTMLLIElement>) {
    if (event.charCode === 13) {
      setOpen(!open);
    }
  }
};

export default withRouter(NavDropdown);
