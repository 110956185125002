import { useEffect, useState } from 'react';
import { GoogleAPIkeys } from 'const';

declare global {
  interface Window {
    google: any;
  }
}

export const useInitGoogleMap = (): [boolean] => {
  const [mapReady, setMapReady] = useState(false);

  useEffect(() => {
    if (!window.google) {
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = `https://maps.google.com/maps/api/js?key=${GoogleAPIkeys.Dobro}`;
      s.async = true;
      s.defer = true;

      document.body.appendChild(s);

      s.addEventListener('load', () => {
        setMapReady(true);
      });
    }
  }, []);

  return [mapReady];
};
