import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ApplicationState } from 'modules/redux-store';
import { AuthenticationThunkActions } from 'modules/authentication';
import { RoutePath } from 'modules/navigation';

interface DispatchProps {
  sendPasswordResetEmail: (email: string) => void;
}

type Props = DispatchProps & RouteComponentProps<any>;

const ForgotPassword: React.FC<Props> = ({
  sendPasswordResetEmail,
  history
}) => {
  const [email, setEmail] = useState('');

  return (
    <div className="wrapper wrapper--narrow">
      <section className="auth">
        <header className="auth__header">
          <h1 className="t-delta s-bottom--lrg">Login</h1>
        </header>
        <form onSubmit={handleSubmit}>
          <div className="auth__content">
            <div className="field s-bottom--lrg">
              <label htmlFor="forgot-password" className="field__lbl">
                E-mail
              </label>
              <input
                id="forgot-password"
                name="email"
                value={email}
                placeholder="Account e-mail address"
                className="input input--med input--text"
                type="email"
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="auth__action">
            <button
              type="submit"
              value="Submit"
              className="btn btn--med btn--primary"
            >
              Send password reset email
            </button>
          </div>
        </form>
      </section>
    </div>
  );

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.currentTarget;

    setEmail(value);
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    await sendPasswordResetEmail(email);

    history.push(RoutePath.Login);
  }
};

export default connect<{}, DispatchProps, null, ApplicationState>(
  null,
  {
    sendPasswordResetEmail: AuthenticationThunkActions.forgotPassword
  }
)(ForgotPassword);
