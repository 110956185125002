enum AddActions {
  AddRequest = 'locations/add-request',
  AddLocations = 'locations/add-location',
  AddError = 'locations/add-error'
}

enum ChangeActions {
  ChangeCity = 'locations/change-city',
  ChangeCompany = 'locations/change-company',
  ChangeLoading = 'locations/change-loading'
}

export const LocationsActionTypes = {
  ...AddActions,
  ...ChangeActions
};
