import * as views from 'views';
import { ACLType } from 'modules/authorization/models';

import { AppRoute, RoutePath } from '../models';

export const anonymousRoutes: AppRoute[] = [
  {
    key: 'login',
    title: 'Log in',
    accessbilityLevel: ACLType.AnonymousOnly,
    component: views.Login,
    exact: true,
    path: RoutePath.Login
  },
  {
    key: 'forgot-password',
    title: 'Forgot password',
    accessbilityLevel: ACLType.AnonymousOnly,
    component: views.ForgotPassword,
    exact: true,
    path: RoutePath.ForgotPassword
  }
];
