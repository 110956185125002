import * as views from 'views';
import { ACLType } from 'modules/authorization/models';

import { AppRoute, RoutePath } from '../models';

export const authenticatedRoutes: AppRoute[] = [
  {
    key: 'locations',
    title: 'Lokacije',
    accessbilityLevel: ACLType.Authenticated,
    component: views.LocationsList,
    exact: true,
    path: RoutePath.Locations
  }
];

export const authenticatedDetailsRoutes: AppRoute[] = [
  {
    key: 'location-details',
    title: 'Detalji lokacije',
    accessbilityLevel: ACLType.Authenticated,
    component: views.LocationDetails,
    exact: false,
    path: RoutePath.LocationDetails
  }
];
