import React, { useEffect, useState, useCallback } from 'react';
import { mapStyles } from 'modules/map';
import { MarkerIcons } from 'const';

import { Company } from '../models';

declare const google: any;

interface Props {
  position: { lat: string | number; lng: string | number };
  newCenter: { lat: string; lng: string };
  company: Company;
  storeName: string;
  handlePositionChange: (position: { lat: string; lng: string }) => void;
}

export const Map: React.FC<Props> = ({
  position,
  handlePositionChange,
  newCenter,
  company,
  storeName
}) => {
  const markers: any = [];
  const [mapState, setMapState] = useState<google.maps.Map>();

  const removeMarkers = useCallback(() => {
    markers.forEach((marker: any) => {
      marker.setMap(null);
    });
  }, [markers]);

  const placeMarker = useCallback(
    (latLng: any, map: google.maps.Map) => {
      removeMarkers();

      const icon = {
        url: MarkerIcons[storeName] || MarkerIcons[company],
        scaledSize: new google.maps.Size(28, 28),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(14, 28)
      };

      const marker: google.maps.Marker = new google.maps.Marker({
        position: latLng,
        icon,
        map: map || mapState
      });

      if (mapState && mapState.getZoom() < 13) {
        mapState.panTo(latLng);
        mapState.setZoom(13);
      }

      markers.push(marker);
    },
    [mapState, markers, removeMarkers, company, storeName]
  );

  useEffect(() => {
    if (mapState && newCenter) {
      mapState.setCenter(
        new google.maps.LatLng(Number(newCenter.lat), Number(newCenter.lng))
      );
      mapState.setZoom(13);
    }
  }, [newCenter, mapState]);

  useEffect(() => {
    if (!mapState) {
      const map: google.maps.Map = new google.maps.Map(
        document.getElementById('map'),
        {
          center: {
            lat: Number(position.lat) || 44.7076475,
            lng: Number(position.lng) || 16.4978158
          },
          zoom: position.lat ? 13 : 8,
          draggableCursor: 'default',
          mapTypeControl: false,
          styles: mapStyles
        }
      );

      map.addListener('click', e => {
        handlePositionChange({ lat: e.latLng.lat(), lng: e.latLng.lng() });
      });

      setMapState(map);
    }

    return () => {
      removeMarkers();
    };
  }, [mapState, position, handlePositionChange, removeMarkers]);

  useEffect(() => {
    if (mapState && position.lat && position.lng) {
      placeMarker(position, mapState);
    }
  }, [mapState, position, placeMarker]);

  return <div id="map" className="map" />;
};
