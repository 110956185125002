import * as React from 'react';
import { AppRoute, getRoute, RoutePath } from 'modules/navigation';
import { Link } from 'react-router-dom';

interface Props {
  dashboardRoute: AppRoute;
}

export const ErrorGeneral: React.FC<Props> = ({
  dashboardRoute = getRoute(RoutePath.Locations)
}) => (
  <section className="v-errorpage">
    <div className="v-errorpage__content">
      <div className="card">
        <h1 className="t-gamma s-bottom--lrg">Tražena stranica ne postoji.</h1>
        <p className="t-zeta s-bottom--lrg">
          Klikom na link se vraćate na početnu stranicu.
        </p>
        <Link to={dashboardRoute.path} className="btn btn--primary btn--med">
          {dashboardRoute.title}
        </Link>
      </div>
    </div>
  </section>
);
