import { useEffect, useState } from 'react';

import { CollectionLastModified } from '../models';

/**
 *
 * @param getLastModified thunk that fetches lastModified collection from firestore
 * @param lastModified fetched lastModified document that corresponds to particular collection
 * @param getCollection thunk that fetches particular collection if it has been modified
 * @param lastModifiedChanging loading of lastModified object in store
 * @param changeCollectionLoading function that sets particular collection loading to false
 */
export const useLastModified = (
  getLastModified: VoidFunction,
  lastModified: CollectionLastModified | undefined,
  getCollection: VoidFunction,
  lastModifiedChanging: boolean,
  changeCollectionLoading?: VoidFunction
) => {
  const [oldTime, setOldTime] = useState(
    lastModified && lastModified.time ? lastModified.time.seconds : 1
  );

  useEffect(() => {
    setOldTime(
      lastModified && lastModified.time ? lastModified.time.seconds : 1
    );
  }, [lastModified]);

  useEffect(() => {
    getLastModified();
  }, [getLastModified]);

  useEffect(() => {
    if (lastModifiedChanging) {
      return;
    }

    let newTime: number;

    if (lastModified && lastModified.time) {
      newTime = lastModified.time.seconds;
    } else {
      newTime = new Date().getTime() / 1000;
    }

    if (newTime > oldTime) {
      getCollection();
    } else {
      if (changeCollectionLoading) {
        changeCollectionLoading();
      }
    }
  }, [
    lastModified,
    getCollection,
    oldTime,
    changeCollectionLoading,
    lastModifiedChanging
  ]);

  return null;
};
