import { ApplicationState } from 'modules/redux-store';
import { Dispatch } from 'redux';

import { FirestoreListenerActions } from './actions';

const unsubscribeListeners = () => async (
  dispatch: Dispatch,
  getState: () => ApplicationState
) => {
  const listeners = getState().listeners;
  for (const prop in listeners) {
    if (listeners.hasOwnProperty(prop)) {
      const unsubscribeListener = await listeners[prop];
      if (unsubscribeListener) {
        await unsubscribeListener();
      }
    }
  }

  await dispatch(FirestoreListenerActions.removeAllListeners());

  return;
};

export const FirestoreListenerThunks = {
  unsubscribeListeners
};
